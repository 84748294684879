import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

/** Stores the incoming and outgoing call details. */
export type Call = {
  __typename?: 'Call';
  /** Twilio Call SID. */
  callSid: Scalars['String']['output'];
  /** Date and time the call was created. */
  date: Scalars['DateTimeISO']['output'];
  /** Date and time the call ended. */
  dateEnded?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Date and time the call was first accepted. */
  dateStarted?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Direction of the call. */
  direction: CallDirection;
  /** Duration of the call in seconds. This is the number of seconds from the time it was accepted until it ended. */
  duration: Scalars['Int']['output'];
  /** Indicates which end terminated the call. */
  endedBy?: Maybe<CallEndedBy>;
  /** Phone number or client name the call is coming from. */
  from: Scalars['String']['output'];
  /** Unique identifier of the Call. */
  id: Scalars['String']['output'];
  routings: Array<CallRouting>;
  /** Status of the call. */
  status: CallStatus;
  /** A summary of what the call was about. */
  summary?: Maybe<Scalars['String']['output']>;
  /** Phone number or client name the call is directed to. */
  to: Scalars['String']['output'];
  /** User assigned to the call. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};


/** Stores the incoming and outgoing call details. */
export type CallRoutingsArgs = {
  cursor?: InputMaybe<CallRoutingWhereUniqueInput>;
  distinct?: InputMaybe<Array<CallRoutingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CallRoutingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CallRoutingWhereInput>;
};


/** Stores the incoming and outgoing call details. */
export type CallUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

/** Input for accepting a call. */
export type CallAcceptInput = {
  /** Twilio Call SID. */
  callSid: Scalars['String']['input'];
};

/** Input for updating the status of a call to 'completed'. */
export type CallCompleteInput = {
  /** Twilio call SID. */
  callSid: Scalars['String']['input'];
};

/** Input for creating a call. */
export type CallCreateInput = {
  /** Phone number or client name the call is directed to. */
  to: Scalars['String']['input'];
};

/** Direction of the call. */
export enum CallDirection {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

/** Indicates which end terminated the call. */
export enum CallEndedBy {
  Agent = 'AGENT',
  Client = 'CLIENT'
}

/** Input for getting a single call record. */
export type CallFilterInput = {
  /** ID of the call to get. */
  id: Scalars['String']['input'];
};

export type CallListRelationFilter = {
  every?: InputMaybe<CallWhereInput>;
  none?: InputMaybe<CallWhereInput>;
  some?: InputMaybe<CallWhereInput>;
};

export type CallOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CallOrderByWithRelationInput = {
  callSid?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  dateEnded?: InputMaybe<SortOrder>;
  dateStarted?: InputMaybe<SortOrder>;
  direction?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  endedBy?: InputMaybe<SortOrder>;
  from?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  routings?: InputMaybe<CallRoutingOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  summary?: InputMaybe<SortOrder>;
  to?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

/** Input for rejecting a call. */
export type CallRejectInput = {
  /** Twilio Call SID. */
  callSid: Scalars['String']['input'];
};

export type CallRelationFilter = {
  is?: InputMaybe<CallWhereInput>;
  isNot?: InputMaybe<CallWhereInput>;
};

export type CallRouting = {
  __typename?: 'CallRouting';
  /** Call that was routed. */
  call: Call;
  callId: Scalars['String']['output'];
  /** Date and time the call was routed. */
  date: Scalars['DateTimeISO']['output'];
  /** Unique identifier of the Call. */
  id: Scalars['String']['output'];
  status: CallRoutingStatus;
  /** User who was assigned to the call. */
  user: User;
  userId: Scalars['String']['output'];
};

export type CallRoutingListRelationFilter = {
  every?: InputMaybe<CallRoutingWhereInput>;
  none?: InputMaybe<CallRoutingWhereInput>;
  some?: InputMaybe<CallRoutingWhereInput>;
};

export type CallRoutingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CallRoutingOrderByWithRelationInput = {
  call?: InputMaybe<CallOrderByWithRelationInput>;
  callId?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum CallRoutingScalarFieldEnum {
  CallId = 'callId',
  Date = 'date',
  Id = 'id',
  Status = 'status',
  UserId = 'userId'
}

export enum CallRoutingStatus {
  Accepted = 'ACCEPTED',
  Assigned = 'ASSIGNED',
  Dialed = 'DIALED',
  Missed = 'MISSED',
  Rejected = 'REJECTED'
}

export type CallRoutingWhereInput = {
  AND?: InputMaybe<Array<CallRoutingWhereInput>>;
  NOT?: InputMaybe<Array<CallRoutingWhereInput>>;
  OR?: InputMaybe<Array<CallRoutingWhereInput>>;
  call?: InputMaybe<CallRelationFilter>;
  callId?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumCallRoutingStatusFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CallRoutingWhereUniqueInput = {
  AND?: InputMaybe<Array<CallRoutingWhereInput>>;
  NOT?: InputMaybe<Array<CallRoutingWhereInput>>;
  OR?: InputMaybe<Array<CallRoutingWhereInput>>;
  call?: InputMaybe<CallRelationFilter>;
  callId?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EnumCallRoutingStatusFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export enum CallScalarFieldEnum {
  CallSid = 'callSid',
  Date = 'date',
  DateEnded = 'dateEnded',
  DateStarted = 'dateStarted',
  Direction = 'direction',
  Duration = 'duration',
  EndedBy = 'endedBy',
  From = 'from',
  Id = 'id',
  Status = 'status',
  Summary = 'summary',
  To = 'to',
  UserId = 'userId'
}

/** Status of a call. */
export enum CallStatus {
  Assigned = 'ASSIGNED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Missed = 'MISSED',
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Rejected = 'REJECTED',
  WrappingUp = 'WRAPPING_UP'
}

/** Input for creating a call. */
export type CallUpdateInput = {
  /** Twilio Call SID. */
  callSid: Scalars['String']['input'];
  /** Status of the call to update. */
  status: CallStatus;
};

/** Input for updating the summary of a call. */
export type CallUpdateSummaryInput = {
  /** ID of the call to update. */
  callSid: Scalars['String']['input'];
  /** Summary of the call. */
  summary: Scalars['String']['input'];
};

export type CallWhereInput = {
  AND?: InputMaybe<Array<CallWhereInput>>;
  NOT?: InputMaybe<Array<CallWhereInput>>;
  OR?: InputMaybe<Array<CallWhereInput>>;
  callSid?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  dateEnded?: InputMaybe<DateTimeNullableFilter>;
  dateStarted?: InputMaybe<DateTimeNullableFilter>;
  direction?: InputMaybe<EnumCallDirectionFilter>;
  duration?: InputMaybe<IntFilter>;
  endedBy?: InputMaybe<EnumCallEndedByNullableFilter>;
  from?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  routings?: InputMaybe<CallRoutingListRelationFilter>;
  status?: InputMaybe<EnumCallStatusFilter>;
  summary?: InputMaybe<StringNullableFilter>;
  to?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type CallWhereUniqueInput = {
  AND?: InputMaybe<Array<CallWhereInput>>;
  NOT?: InputMaybe<Array<CallWhereInput>>;
  OR?: InputMaybe<Array<CallWhereInput>>;
  callSid?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<DateTimeFilter>;
  dateEnded?: InputMaybe<DateTimeNullableFilter>;
  dateStarted?: InputMaybe<DateTimeNullableFilter>;
  direction?: InputMaybe<EnumCallDirectionFilter>;
  duration?: InputMaybe<IntFilter>;
  endedBy?: InputMaybe<EnumCallEndedByNullableFilter>;
  from?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  routings?: InputMaybe<CallRoutingListRelationFilter>;
  status?: InputMaybe<EnumCallStatusFilter>;
  summary?: InputMaybe<StringNullableFilter>;
  to?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type EnumCallDirectionFilter = {
  equals?: InputMaybe<CallDirection>;
  in?: InputMaybe<Array<CallDirection>>;
  not?: InputMaybe<NestedEnumCallDirectionFilter>;
  notIn?: InputMaybe<Array<CallDirection>>;
};

export type EnumCallEndedByNullableFilter = {
  equals?: InputMaybe<CallEndedBy>;
  in?: InputMaybe<Array<CallEndedBy>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedEnumCallEndedByNullableFilter>;
  notIn?: InputMaybe<Array<CallEndedBy>>;
};

export type EnumCallRoutingStatusFilter = {
  equals?: InputMaybe<CallRoutingStatus>;
  in?: InputMaybe<Array<CallRoutingStatus>>;
  not?: InputMaybe<NestedEnumCallRoutingStatusFilter>;
  notIn?: InputMaybe<Array<CallRoutingStatus>>;
};

export type EnumCallStatusFilter = {
  equals?: InputMaybe<CallStatus>;
  in?: InputMaybe<Array<CallStatus>>;
  not?: InputMaybe<NestedEnumCallStatusFilter>;
  notIn?: InputMaybe<Array<CallStatus>>;
};

export type EnumGenderNullableFilter = {
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedEnumGenderNullableFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type EnumSalutationNullableFilter = {
  equals?: InputMaybe<Salutation>;
  in?: InputMaybe<Array<Salutation>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedEnumSalutationNullableFilter>;
  notIn?: InputMaybe<Array<Salutation>>;
};

export type EnumUserAvailabilityStatusFilter = {
  equals?: InputMaybe<UserAvailabilityStatus>;
  in?: InputMaybe<Array<UserAvailabilityStatus>>;
  not?: InputMaybe<NestedEnumUserAvailabilityStatusFilter>;
  notIn?: InputMaybe<Array<UserAvailabilityStatus>>;
};

export type EnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

/** Gender. */
export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept a call. */
  callAccept: Call;
  /** Updates the status of a call to "wrap. Only assigned agents can do this. Only calls in a "wrap up" status can be completed. */
  callComplete: Call;
  /** Creates a call in the database. This requires the "user:create" Auth0 permission. */
  callCreate: Call;
  /** Hang up an outbound call. */
  callHangUp: Call;
  /** Reject a call. */
  callReject: Call;
  /** Updates a call in the database. Returns the updated call. This requires the "call:update" Auth0 permission. */
  callUpdate: Call;
  /** Update the summary field of a call. */
  callUpdateSummary: Call;
  /** Updates a system preference. */
  systemPreferenceUpdate: SystemPreference;
  /** Creates a user in the database. This requires the "user:create" Auth0 permission. */
  userCreate: User;
  /** Updates a user in the database. Returns the updated user. This requires the "user:update" Auth0 permission. */
  userUpdate: User;
  /** Updates the user's availability status. */
  userUpdateAvailability: User;
  /** Deletes a user in the database. Returns the deleted user. This requires the "user:delete" Auth0 permission. */
  usersDelete: Array<User>;
};


export type MutationCallAcceptArgs = {
  input: CallAcceptInput;
};


export type MutationCallCompleteArgs = {
  input: CallCompleteInput;
};


export type MutationCallCreateArgs = {
  input: CallCreateInput;
};


export type MutationCallHangUpArgs = {
  input: CallRejectInput;
};


export type MutationCallRejectArgs = {
  input: CallRejectInput;
};


export type MutationCallUpdateArgs = {
  input: CallUpdateInput;
};


export type MutationCallUpdateSummaryArgs = {
  input: CallUpdateSummaryInput;
};


export type MutationSystemPreferenceUpdateArgs = {
  input: SystemPreferenceUpdateInput;
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};


export type MutationUserUpdateAvailabilityArgs = {
  input: UserUpdateAvailabilityInput;
};


export type MutationUsersDeleteArgs = {
  input: UsersDeleteInput;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedEnumCallDirectionFilter = {
  equals?: InputMaybe<CallDirection>;
  in?: InputMaybe<Array<CallDirection>>;
  not?: InputMaybe<NestedEnumCallDirectionFilter>;
  notIn?: InputMaybe<Array<CallDirection>>;
};

export type NestedEnumCallEndedByNullableFilter = {
  equals?: InputMaybe<CallEndedBy>;
  in?: InputMaybe<Array<CallEndedBy>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedEnumCallEndedByNullableFilter>;
  notIn?: InputMaybe<Array<CallEndedBy>>;
};

export type NestedEnumCallRoutingStatusFilter = {
  equals?: InputMaybe<CallRoutingStatus>;
  in?: InputMaybe<Array<CallRoutingStatus>>;
  not?: InputMaybe<NestedEnumCallRoutingStatusFilter>;
  notIn?: InputMaybe<Array<CallRoutingStatus>>;
};

export type NestedEnumCallStatusFilter = {
  equals?: InputMaybe<CallStatus>;
  in?: InputMaybe<Array<CallStatus>>;
  not?: InputMaybe<NestedEnumCallStatusFilter>;
  notIn?: InputMaybe<Array<CallStatus>>;
};

export type NestedEnumGenderNullableFilter = {
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedEnumGenderNullableFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type NestedEnumSalutationNullableFilter = {
  equals?: InputMaybe<Salutation>;
  in?: InputMaybe<Array<Salutation>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedEnumSalutationNullableFilter>;
  notIn?: InputMaybe<Array<Salutation>>;
};

export type NestedEnumUserAvailabilityStatusFilter = {
  equals?: InputMaybe<UserAvailabilityStatus>;
  in?: InputMaybe<Array<UserAvailabilityStatus>>;
  not?: InputMaybe<NestedEnumUserAvailabilityStatusFilter>;
  notIn?: InputMaybe<Array<UserAvailabilityStatus>>;
};

export type NestedEnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  /** Retrieve a single call. */
  call?: Maybe<Call>;
  /** Retrieve call records. */
  calls: Array<Call>;
  /** Retrieve call records. */
  callsAgent: Array<Call>;
  /** Retrieve a system preference. */
  systemPreference?: Maybe<SystemPreference>;
  /** Retrieve the current user.. */
  userCurrent: User;
  /** Retrieves all the users in the database. Returns an empty array when there is no user in the system. This requires the "user:read" Auth0 permission. */
  users: Array<User>;
};


export type QueryCallArgs = {
  filter: CallFilterInput;
};


export type QuerySystemPreferenceArgs = {
  filter: SystemPreferenceInput;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilterInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

/** User salutations. */
export enum Salutation {
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS',
  PreferNotToSay = 'PREFER_NOT_TO_SAY'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Subscribes to call events. */
  callEvent: Call;
  /** Subscribe to system preference events. */
  systemPreferenceEvent: SystemPreference;
  /** Subscribes to the user events. */
  userEvent: User;
};

/** System preferences used to store global settings. */
export type SystemPreference = {
  __typename?: 'SystemPreference';
  /** Description of the system preference. */
  description: Scalars['String']['output'];
  /** Unique identifier of the System Preference. */
  id: Scalars['String']['output'];
  /** Unique key of the system preference entry. There should be no spaces and special characters. Use underscores instead. */
  key: Scalars['String']['output'];
  /** Name of the system preference entry. */
  name: Scalars['String']['output'];
  /** Value of the system preference. Tracking the valid values of this is up to the developer. */
  value: Scalars['String']['output'];
};

/** Input for getting a system preference. */
export type SystemPreferenceInput = {
  /** Unique key of the system preference. */
  key: Scalars['String']['input'];
};

/** Input for updating a system preference record.. */
export type SystemPreferenceUpdateInput = {
  /** Description of the system preference. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Unique key of the system preference entry. There should be no spaces and special characters. Use underscores instead. */
  key?: InputMaybe<Scalars['String']['input']>;
  /** Name of the system preference entry. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Value of the system preference. */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** User record used to manage user access and permission. */
export type User = {
  __typename?: 'User';
  /** User's availability details. */
  availability: UserAvailability;
  /** Call routings related to the user. */
  callRoutings: Array<CallRouting>;
  /** The calls associated to the user. */
  calls: Array<Call>;
  /** Email address of the user. */
  email: Scalars['String']['output'];
  /** Unique identifier of the user. */
  id: Scalars['String']['output'];
  /** Personal details of the user. */
  profile: UserProfile;
  /** Third-party ID of the user. e.g.: Auth0 User ID. */
  thirdPartyId: Scalars['String']['output'];
  /** Type of user. */
  type: UserType;
};


/** User record used to manage user access and permission. */
export type UserCallRoutingsArgs = {
  cursor?: InputMaybe<CallRoutingWhereUniqueInput>;
  distinct?: InputMaybe<Array<CallRoutingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CallRoutingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CallRoutingWhereInput>;
};


/** User record used to manage user access and permission. */
export type UserCallsArgs = {
  cursor?: InputMaybe<CallWhereUniqueInput>;
  distinct?: InputMaybe<Array<CallScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CallOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CallWhereInput>;
};

export type UserAvailability = {
  __typename?: 'UserAvailability';
  /** User availability status. This could be automatically set by the system. */
  status: UserAvailabilityStatus;
  /** Date and time the availability status was set. */
  updatedDate: Scalars['DateTimeISO']['output'];
};

export type UserAvailabilityCompositeFilter = {
  equals?: InputMaybe<UserAvailabilityObjectEqualityInput>;
  is?: InputMaybe<UserAvailabilityWhereInput>;
  isNot?: InputMaybe<UserAvailabilityWhereInput>;
};

export type UserAvailabilityObjectEqualityInput = {
  status: UserAvailabilityStatus;
  updatedDate: Scalars['DateTimeISO']['input'];
};

export type UserAvailabilityOrderByInput = {
  status?: InputMaybe<SortOrder>;
  updatedDate?: InputMaybe<SortOrder>;
};

/** User availability status. */
export enum UserAvailabilityStatus {
  Available = 'AVAILABLE',
  Break = 'BREAK',
  Busy = 'BUSY',
  Offline = 'OFFLINE',
  OnACall = 'ON_A_CALL',
  Ringing = 'RINGING',
  WrappingUp = 'WRAPPING_UP'
}

export type UserAvailabilityWhereInput = {
  AND?: InputMaybe<Array<UserAvailabilityWhereInput>>;
  NOT?: InputMaybe<Array<UserAvailabilityWhereInput>>;
  OR?: InputMaybe<Array<UserAvailabilityWhereInput>>;
  status?: InputMaybe<EnumUserAvailabilityStatusFilter>;
  updatedDate?: InputMaybe<DateTimeFilter>;
};

/** User availability input for creating a user. */
export type UserCreateAvailabilityInput = {
  /** Availability status of the user. */
  status: UserAvailabilityStatus;
};

/** Input for creating a user. */
export type UserCreateInput = {
  /** Availability of the user. */
  availability?: InputMaybe<UserCreateAvailabilityInput>;
  /** Email of the user. This will also serve as the user's username. */
  email: Scalars['String']['input'];
  /** Profile of the user. */
  profile: UserCreateProfileInput;
  /** Third--party ID of the user. This could bhe the ID of the user from the identity provider. */
  thirdPartyId: Scalars['String']['input'];
  /** Type of the user. */
  type: UserType;
};

/** Profile input for creating a user. */
export type UserCreateProfileInput = {
  /** Full name of the user. */
  fullName: Scalars['String']['input'];
  /** Gender of the user. */
  gender: Gender;
  /** Initials of the user. */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Nickname of the user. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** Salutation of the user. */
  salutation?: InputMaybe<Salutation>;
};

export type UserNullableRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserOrderByWithRelationInput = {
  availability?: InputMaybe<UserAvailabilityOrderByInput>;
  callRoutings?: InputMaybe<CallRoutingOrderByRelationAggregateInput>;
  calls?: InputMaybe<CallOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  profile?: InputMaybe<UserProfileOrderByInput>;
  thirdPartyId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

/** Personal information of the user. */
export type UserProfile = {
  __typename?: 'UserProfile';
  /** Full name of the user. */
  fullName: Scalars['String']['output'];
  /** Gender of the user. */
  gender?: Maybe<Gender>;
  /** Initials of the user. This will be auto generated. */
  initials?: Maybe<Scalars['String']['output']>;
  /** Nickname of the user. */
  nickname?: Maybe<Scalars['String']['output']>;
  /** Salutation of the user. */
  salutation?: Maybe<Salutation>;
};

export type UserProfileCompositeFilter = {
  equals?: InputMaybe<UserProfileObjectEqualityInput>;
  is?: InputMaybe<UserProfileWhereInput>;
  isNot?: InputMaybe<UserProfileWhereInput>;
};

export type UserProfileObjectEqualityInput = {
  fullName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  initials?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Salutation>;
};

export type UserProfileOrderByInput = {
  fullName?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  initials?: InputMaybe<SortOrder>;
  nickname?: InputMaybe<SortOrder>;
  salutation?: InputMaybe<SortOrder>;
};

export type UserProfileWhereInput = {
  AND?: InputMaybe<Array<UserProfileWhereInput>>;
  NOT?: InputMaybe<Array<UserProfileWhereInput>>;
  OR?: InputMaybe<Array<UserProfileWhereInput>>;
  fullName?: InputMaybe<StringFilter>;
  gender?: InputMaybe<EnumGenderNullableFilter>;
  initials?: InputMaybe<StringNullableFilter>;
  nickname?: InputMaybe<StringNullableFilter>;
  salutation?: InputMaybe<EnumSalutationNullableFilter>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

/** Types of  a user. */
export enum UserType {
  Default = 'DEFAULT',
  Owner = 'OWNER'
}

/** Update user availability input. */
export type UserUpdateAvailabilityInput = {
  /** User availability status. */
  availabilityStatus: UserAvailabilityStatus;
};

/** Input for updating a user. */
export type UserUpdateInput = {
  /** Email of the user. This will also serve as the user's username. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** ID of the user. */
  id: Scalars['String']['input'];
  /** Profile of the user. */
  profile: UserUpdateProfileInput;
  /** Third--party ID of the user. This could bhe the ID of the user from the identity provider. */
  thirdPartyId: Scalars['String']['input'];
  /** Type of the user. */
  type?: InputMaybe<UserType>;
};

/** Profile input for updating a user. */
export type UserUpdateProfileInput = {
  /** Full name of the user. */
  fullName: Scalars['String']['input'];
  /** Gender of the user. */
  gender: Gender;
  /** Initials of the user. */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Nickname of the user. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** Salutation of the user. */
  salutation?: InputMaybe<Salutation>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  availability?: InputMaybe<UserAvailabilityCompositeFilter>;
  callRoutings?: InputMaybe<CallRoutingListRelationFilter>;
  calls?: InputMaybe<CallListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  profile?: InputMaybe<UserProfileCompositeFilter>;
  thirdPartyId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumUserTypeFilter>;
};

/** Input for deleting one or more users. */
export type UsersDeleteInput = {
  /** IDs of the user. */
  ids: Array<Scalars['String']['input']>;
};

/** Filters for retrieving users. */
export type UsersFilterInput = {
  /** Email address of the user. */
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Arrays of user IDs of the user. */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Full or partial name of the user. This currently requires the exact names. */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CallAcceptMutationVariables = Exact<{
  input: CallAcceptInput;
}>;


export type CallAcceptMutation = { __typename?: 'Mutation', callAccept: { __typename?: 'Call', id: string, callSid: string, date: any, direction: CallDirection, from: string, status: CallStatus, to: string, userId?: string | null } };

export type CallCompleteMutationVariables = Exact<{
  input: CallCompleteInput;
}>;


export type CallCompleteMutation = { __typename?: 'Mutation', callComplete: { __typename?: 'Call', id: string, callSid: string, date: any, dateStarted?: any | null, dateEnded?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, endedBy?: CallEndedBy | null, to: string, userId?: string | null } };

export type CallCreateMutationVariables = Exact<{
  input: CallCreateInput;
}>;


export type CallCreateMutation = { __typename?: 'Mutation', callCreate: { __typename?: 'Call', id: string, callSid: string, date: any, dateStarted?: any | null, dateEnded?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, endedBy?: CallEndedBy | null, to: string, userId?: string | null } };

export type CallHangUpMutationVariables = Exact<{
  input: CallRejectInput;
}>;


export type CallHangUpMutation = { __typename?: 'Mutation', callHangUp: { __typename?: 'Call', id: string, callSid: string, date: any, dateStarted?: any | null, dateEnded?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, endedBy?: CallEndedBy | null, to: string, userId?: string | null } };

export type CallRejectMutationVariables = Exact<{
  input: CallRejectInput;
}>;


export type CallRejectMutation = { __typename?: 'Mutation', callReject: { __typename?: 'Call', id: string, callSid: string, date: any, direction: CallDirection, from: string, status: CallStatus, to: string, userId?: string | null } };

export type CallUpdateSummaryMutationVariables = Exact<{
  input: CallUpdateSummaryInput;
}>;


export type CallUpdateSummaryMutation = { __typename?: 'Mutation', callUpdateSummary: { __typename?: 'Call', id: string, callSid: string, date: any, dateStarted?: any | null, dateEnded?: any | null, direction: CallDirection, duration: number, from: string, summary?: string | null, status: CallStatus, endedBy?: CallEndedBy | null, to: string, userId?: string | null } };

export type CallsAgentQueryVariables = Exact<{ [key: string]: never; }>;


export type CallsAgentQuery = { __typename?: 'Query', callsAgent: Array<{ __typename?: 'Call', id: string, callSid: string, date: any, dateEnded?: any | null, dateStarted?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, summary?: string | null, to: string, user?: { __typename?: 'User', id: string, thirdPartyId: string, profile: { __typename?: 'UserProfile', fullName: string, gender?: Gender | null, initials?: string | null, nickname?: string | null, salutation?: Salutation | null } } | null }> };

export type CallQueryVariables = Exact<{
  filter: CallFilterInput;
}>;


export type CallQuery = { __typename?: 'Query', call?: { __typename?: 'Call', id: string, callSid: string, date: any, dateEnded?: any | null, dateStarted?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, summary?: string | null, to: string, userId?: string | null, user?: { __typename?: 'User', profile: { __typename?: 'UserProfile', fullName: string } } | null, routings: Array<{ __typename?: 'CallRouting', id: string, date: any, status: CallRoutingStatus, user: { __typename?: 'User', id: string, profile: { __typename?: 'UserProfile', fullName: string } } }> } | null };

export type CallEventSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CallEventSubscription = { __typename?: 'Subscription', callEvent: { __typename?: 'Call', id: string, callSid: string, date: any, direction: CallDirection, from: string, status: CallStatus, to: string } };

export type SystemPreferenceUpdateMutationVariables = Exact<{
  input: SystemPreferenceUpdateInput;
}>;


export type SystemPreferenceUpdateMutation = { __typename?: 'Mutation', systemPreferenceUpdate: { __typename?: 'SystemPreference', id: string, description: string, key: string, name: string, value: string } };

export type SystemPreferenceQueryVariables = Exact<{
  filter: SystemPreferenceInput;
}>;


export type SystemPreferenceQuery = { __typename?: 'Query', systemPreference?: { __typename?: 'SystemPreference', id: string, description: string, key: string, name: string, value: string } | null };

export type SystemPreferenceEventSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SystemPreferenceEventSubscription = { __typename?: 'Subscription', systemPreferenceEvent: { __typename?: 'SystemPreference', id: string, description: string, key: string, name: string, value: string } };

export type UserUpdateAvailabilityMutationVariables = Exact<{
  input: UserUpdateAvailabilityInput;
}>;


export type UserUpdateAvailabilityMutation = { __typename?: 'Mutation', userUpdateAvailability: { __typename?: 'User', availability: { __typename?: 'UserAvailability', status: UserAvailabilityStatus, updatedDate: any } } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', email: string, id: string, availability: { __typename?: 'UserAvailability', status: UserAvailabilityStatus, updatedDate: any }, profile: { __typename?: 'UserProfile', fullName: string } }> };

export type UserEventSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserEventSubscription = { __typename?: 'Subscription', userEvent: { __typename?: 'User', id: string, email: string, thirdPartyId: string, type: UserType, availability: { __typename?: 'UserAvailability', status: UserAvailabilityStatus, updatedDate: any }, profile: { __typename?: 'UserProfile', fullName: string, gender?: Gender | null, initials?: string | null, nickname?: string | null, salutation?: Salutation | null } } };


export const CallAcceptDocument = gql`
    mutation CallAccept($input: CallAcceptInput!) {
  callAccept(input: $input) {
    id
    callSid
    date
    direction
    from
    status
    to
    userId
  }
}
    `;
export type CallAcceptMutationFn = Apollo.MutationFunction<CallAcceptMutation, CallAcceptMutationVariables>;

/**
 * __useCallAcceptMutation__
 *
 * To run a mutation, you first call `useCallAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callAcceptMutation, { data, loading, error }] = useCallAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallAcceptMutation(baseOptions?: Apollo.MutationHookOptions<CallAcceptMutation, CallAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallAcceptMutation, CallAcceptMutationVariables>(CallAcceptDocument, options);
      }
export type CallAcceptMutationHookResult = ReturnType<typeof useCallAcceptMutation>;
export type CallAcceptMutationResult = Apollo.MutationResult<CallAcceptMutation>;
export type CallAcceptMutationOptions = Apollo.BaseMutationOptions<CallAcceptMutation, CallAcceptMutationVariables>;
export const CallCompleteDocument = gql`
    mutation CallComplete($input: CallCompleteInput!) {
  callComplete(input: $input) {
    id
    callSid
    date
    dateStarted
    dateEnded
    direction
    duration
    from
    status
    endedBy
    to
    userId
  }
}
    `;
export type CallCompleteMutationFn = Apollo.MutationFunction<CallCompleteMutation, CallCompleteMutationVariables>;

/**
 * __useCallCompleteMutation__
 *
 * To run a mutation, you first call `useCallCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callCompleteMutation, { data, loading, error }] = useCallCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallCompleteMutation(baseOptions?: Apollo.MutationHookOptions<CallCompleteMutation, CallCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallCompleteMutation, CallCompleteMutationVariables>(CallCompleteDocument, options);
      }
export type CallCompleteMutationHookResult = ReturnType<typeof useCallCompleteMutation>;
export type CallCompleteMutationResult = Apollo.MutationResult<CallCompleteMutation>;
export type CallCompleteMutationOptions = Apollo.BaseMutationOptions<CallCompleteMutation, CallCompleteMutationVariables>;
export const CallCreateDocument = gql`
    mutation CallCreate($input: CallCreateInput!) {
  callCreate(input: $input) {
    id
    callSid
    date
    dateStarted
    dateEnded
    direction
    duration
    from
    status
    endedBy
    to
    userId
  }
}
    `;
export type CallCreateMutationFn = Apollo.MutationFunction<CallCreateMutation, CallCreateMutationVariables>;

/**
 * __useCallCreateMutation__
 *
 * To run a mutation, you first call `useCallCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callCreateMutation, { data, loading, error }] = useCallCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallCreateMutation(baseOptions?: Apollo.MutationHookOptions<CallCreateMutation, CallCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallCreateMutation, CallCreateMutationVariables>(CallCreateDocument, options);
      }
export type CallCreateMutationHookResult = ReturnType<typeof useCallCreateMutation>;
export type CallCreateMutationResult = Apollo.MutationResult<CallCreateMutation>;
export type CallCreateMutationOptions = Apollo.BaseMutationOptions<CallCreateMutation, CallCreateMutationVariables>;
export const CallHangUpDocument = gql`
    mutation CallHangUp($input: CallRejectInput!) {
  callHangUp(input: $input) {
    id
    callSid
    date
    dateStarted
    dateEnded
    direction
    duration
    from
    status
    endedBy
    to
    userId
  }
}
    `;
export type CallHangUpMutationFn = Apollo.MutationFunction<CallHangUpMutation, CallHangUpMutationVariables>;

/**
 * __useCallHangUpMutation__
 *
 * To run a mutation, you first call `useCallHangUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallHangUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callHangUpMutation, { data, loading, error }] = useCallHangUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallHangUpMutation(baseOptions?: Apollo.MutationHookOptions<CallHangUpMutation, CallHangUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallHangUpMutation, CallHangUpMutationVariables>(CallHangUpDocument, options);
      }
export type CallHangUpMutationHookResult = ReturnType<typeof useCallHangUpMutation>;
export type CallHangUpMutationResult = Apollo.MutationResult<CallHangUpMutation>;
export type CallHangUpMutationOptions = Apollo.BaseMutationOptions<CallHangUpMutation, CallHangUpMutationVariables>;
export const CallRejectDocument = gql`
    mutation CallReject($input: CallRejectInput!) {
  callReject(input: $input) {
    id
    callSid
    date
    direction
    from
    status
    to
    userId
  }
}
    `;
export type CallRejectMutationFn = Apollo.MutationFunction<CallRejectMutation, CallRejectMutationVariables>;

/**
 * __useCallRejectMutation__
 *
 * To run a mutation, you first call `useCallRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callRejectMutation, { data, loading, error }] = useCallRejectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallRejectMutation(baseOptions?: Apollo.MutationHookOptions<CallRejectMutation, CallRejectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallRejectMutation, CallRejectMutationVariables>(CallRejectDocument, options);
      }
export type CallRejectMutationHookResult = ReturnType<typeof useCallRejectMutation>;
export type CallRejectMutationResult = Apollo.MutationResult<CallRejectMutation>;
export type CallRejectMutationOptions = Apollo.BaseMutationOptions<CallRejectMutation, CallRejectMutationVariables>;
export const CallUpdateSummaryDocument = gql`
    mutation CallUpdateSummary($input: CallUpdateSummaryInput!) {
  callUpdateSummary(input: $input) {
    id
    callSid
    date
    dateStarted
    dateEnded
    direction
    duration
    from
    summary
    status
    endedBy
    to
    userId
  }
}
    `;
export type CallUpdateSummaryMutationFn = Apollo.MutationFunction<CallUpdateSummaryMutation, CallUpdateSummaryMutationVariables>;

/**
 * __useCallUpdateSummaryMutation__
 *
 * To run a mutation, you first call `useCallUpdateSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallUpdateSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callUpdateSummaryMutation, { data, loading, error }] = useCallUpdateSummaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallUpdateSummaryMutation(baseOptions?: Apollo.MutationHookOptions<CallUpdateSummaryMutation, CallUpdateSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallUpdateSummaryMutation, CallUpdateSummaryMutationVariables>(CallUpdateSummaryDocument, options);
      }
export type CallUpdateSummaryMutationHookResult = ReturnType<typeof useCallUpdateSummaryMutation>;
export type CallUpdateSummaryMutationResult = Apollo.MutationResult<CallUpdateSummaryMutation>;
export type CallUpdateSummaryMutationOptions = Apollo.BaseMutationOptions<CallUpdateSummaryMutation, CallUpdateSummaryMutationVariables>;
export const CallsAgentDocument = gql`
    query CallsAgent {
  callsAgent {
    id
    callSid
    date
    dateEnded
    dateStarted
    direction
    duration
    from
    status
    summary
    to
    user {
      id
      profile {
        fullName
        gender
        initials
        nickname
        salutation
      }
      thirdPartyId
    }
  }
}
    `;

/**
 * __useCallsAgentQuery__
 *
 * To run a query within a React component, call `useCallsAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsAgentQuery({
 *   variables: {
 *   },
 * });
 */
export function useCallsAgentQuery(baseOptions?: Apollo.QueryHookOptions<CallsAgentQuery, CallsAgentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CallsAgentQuery, CallsAgentQueryVariables>(CallsAgentDocument, options);
      }
export function useCallsAgentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallsAgentQuery, CallsAgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CallsAgentQuery, CallsAgentQueryVariables>(CallsAgentDocument, options);
        }
export function useCallsAgentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CallsAgentQuery, CallsAgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CallsAgentQuery, CallsAgentQueryVariables>(CallsAgentDocument, options);
        }
export type CallsAgentQueryHookResult = ReturnType<typeof useCallsAgentQuery>;
export type CallsAgentLazyQueryHookResult = ReturnType<typeof useCallsAgentLazyQuery>;
export type CallsAgentSuspenseQueryHookResult = ReturnType<typeof useCallsAgentSuspenseQuery>;
export type CallsAgentQueryResult = Apollo.QueryResult<CallsAgentQuery, CallsAgentQueryVariables>;
export const CallDocument = gql`
    query Call($filter: CallFilterInput!) {
  call(filter: $filter) {
    id
    callSid
    date
    dateEnded
    dateStarted
    direction
    duration
    from
    status
    summary
    to
    userId
    user {
      profile {
        fullName
      }
    }
    routings {
      id
      date
      status
      user {
        id
        profile {
          fullName
        }
      }
    }
  }
}
    `;

/**
 * __useCallQuery__
 *
 * To run a query within a React component, call `useCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCallQuery(baseOptions: Apollo.QueryHookOptions<CallQuery, CallQueryVariables> & ({ variables: CallQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CallQuery, CallQueryVariables>(CallDocument, options);
      }
export function useCallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallQuery, CallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CallQuery, CallQueryVariables>(CallDocument, options);
        }
export function useCallSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CallQuery, CallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CallQuery, CallQueryVariables>(CallDocument, options);
        }
export type CallQueryHookResult = ReturnType<typeof useCallQuery>;
export type CallLazyQueryHookResult = ReturnType<typeof useCallLazyQuery>;
export type CallSuspenseQueryHookResult = ReturnType<typeof useCallSuspenseQuery>;
export type CallQueryResult = Apollo.QueryResult<CallQuery, CallQueryVariables>;
export const CallEventDocument = gql`
    subscription CallEvent {
  callEvent {
    id
    callSid
    date
    direction
    from
    status
    to
  }
}
    `;

/**
 * __useCallEventSubscription__
 *
 * To run a query within a React component, call `useCallEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCallEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCallEventSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CallEventSubscription, CallEventSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CallEventSubscription, CallEventSubscriptionVariables>(CallEventDocument, options);
      }
export type CallEventSubscriptionHookResult = ReturnType<typeof useCallEventSubscription>;
export type CallEventSubscriptionResult = Apollo.SubscriptionResult<CallEventSubscription>;
export const SystemPreferenceUpdateDocument = gql`
    mutation SystemPreferenceUpdate($input: SystemPreferenceUpdateInput!) {
  systemPreferenceUpdate(input: $input) {
    id
    description
    key
    name
    value
  }
}
    `;
export type SystemPreferenceUpdateMutationFn = Apollo.MutationFunction<SystemPreferenceUpdateMutation, SystemPreferenceUpdateMutationVariables>;

/**
 * __useSystemPreferenceUpdateMutation__
 *
 * To run a mutation, you first call `useSystemPreferenceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSystemPreferenceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [systemPreferenceUpdateMutation, { data, loading, error }] = useSystemPreferenceUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSystemPreferenceUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SystemPreferenceUpdateMutation, SystemPreferenceUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SystemPreferenceUpdateMutation, SystemPreferenceUpdateMutationVariables>(SystemPreferenceUpdateDocument, options);
      }
export type SystemPreferenceUpdateMutationHookResult = ReturnType<typeof useSystemPreferenceUpdateMutation>;
export type SystemPreferenceUpdateMutationResult = Apollo.MutationResult<SystemPreferenceUpdateMutation>;
export type SystemPreferenceUpdateMutationOptions = Apollo.BaseMutationOptions<SystemPreferenceUpdateMutation, SystemPreferenceUpdateMutationVariables>;
export const SystemPreferenceDocument = gql`
    query SystemPreference($filter: SystemPreferenceInput!) {
  systemPreference(filter: $filter) {
    id
    description
    key
    name
    value
  }
}
    `;

/**
 * __useSystemPreferenceQuery__
 *
 * To run a query within a React component, call `useSystemPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemPreferenceQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSystemPreferenceQuery(baseOptions: Apollo.QueryHookOptions<SystemPreferenceQuery, SystemPreferenceQueryVariables> & ({ variables: SystemPreferenceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemPreferenceQuery, SystemPreferenceQueryVariables>(SystemPreferenceDocument, options);
      }
export function useSystemPreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemPreferenceQuery, SystemPreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemPreferenceQuery, SystemPreferenceQueryVariables>(SystemPreferenceDocument, options);
        }
export function useSystemPreferenceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SystemPreferenceQuery, SystemPreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SystemPreferenceQuery, SystemPreferenceQueryVariables>(SystemPreferenceDocument, options);
        }
export type SystemPreferenceQueryHookResult = ReturnType<typeof useSystemPreferenceQuery>;
export type SystemPreferenceLazyQueryHookResult = ReturnType<typeof useSystemPreferenceLazyQuery>;
export type SystemPreferenceSuspenseQueryHookResult = ReturnType<typeof useSystemPreferenceSuspenseQuery>;
export type SystemPreferenceQueryResult = Apollo.QueryResult<SystemPreferenceQuery, SystemPreferenceQueryVariables>;
export const SystemPreferenceEventDocument = gql`
    subscription SystemPreferenceEvent {
  systemPreferenceEvent {
    id
    description
    key
    name
    value
  }
}
    `;

/**
 * __useSystemPreferenceEventSubscription__
 *
 * To run a query within a React component, call `useSystemPreferenceEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSystemPreferenceEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemPreferenceEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSystemPreferenceEventSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SystemPreferenceEventSubscription, SystemPreferenceEventSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SystemPreferenceEventSubscription, SystemPreferenceEventSubscriptionVariables>(SystemPreferenceEventDocument, options);
      }
export type SystemPreferenceEventSubscriptionHookResult = ReturnType<typeof useSystemPreferenceEventSubscription>;
export type SystemPreferenceEventSubscriptionResult = Apollo.SubscriptionResult<SystemPreferenceEventSubscription>;
export const UserUpdateAvailabilityDocument = gql`
    mutation UserUpdateAvailability($input: UserUpdateAvailabilityInput!) {
  userUpdateAvailability(input: $input) {
    availability {
      status
      updatedDate
    }
  }
}
    `;
export type UserUpdateAvailabilityMutationFn = Apollo.MutationFunction<UserUpdateAvailabilityMutation, UserUpdateAvailabilityMutationVariables>;

/**
 * __useUserUpdateAvailabilityMutation__
 *
 * To run a mutation, you first call `useUserUpdateAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateAvailabilityMutation, { data, loading, error }] = useUserUpdateAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateAvailabilityMutation, UserUpdateAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateAvailabilityMutation, UserUpdateAvailabilityMutationVariables>(UserUpdateAvailabilityDocument, options);
      }
export type UserUpdateAvailabilityMutationHookResult = ReturnType<typeof useUserUpdateAvailabilityMutation>;
export type UserUpdateAvailabilityMutationResult = Apollo.MutationResult<UserUpdateAvailabilityMutation>;
export type UserUpdateAvailabilityMutationOptions = Apollo.BaseMutationOptions<UserUpdateAvailabilityMutation, UserUpdateAvailabilityMutationVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    availability {
      status
      updatedDate
    }
    email
    id
    profile {
      fullName
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserEventDocument = gql`
    subscription UserEvent {
  userEvent {
    id
    availability {
      status
      updatedDate
    }
    email
    profile {
      fullName
      gender
      initials
      nickname
      salutation
    }
    thirdPartyId
    type
  }
}
    `;

/**
 * __useUserEventSubscription__
 *
 * To run a query within a React component, call `useUserEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserEventSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UserEventSubscription, UserEventSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserEventSubscription, UserEventSubscriptionVariables>(UserEventDocument, options);
      }
export type UserEventSubscriptionHookResult = ReturnType<typeof useUserEventSubscription>;
export type UserEventSubscriptionResult = Apollo.SubscriptionResult<UserEventSubscription>;