"use client";

/**
 * Third-party libraries.
 */
import { notification as antdNotification } from "antd";
import React, { PropsWithChildren } from "react";

import { NotificationInstance } from "antd/es/notification/interface";

/**
 * Application context.
 */

export type NotificationContext = {
  /**
   * Ant Design notification instance.
   *
   * Use this to show notifications.
   */
  notification: NotificationInstance;
};

/**
 * Application context.
 */
const NotificationContext = React.createContext<NotificationContext>({
  notification: {
    success: () => {},
    error: () => {},
    info: () => {},
    warning: () => {},
    open: () => {},
    destroy: () => {},
  },
});

/**
 * Use Application Context hook.
 */
export const useNotificationContext = () => {
  return React.useContext(NotificationContext);
};

/**
 * Application context provider.
 */
export const NotificationContextProvider = ({
  children,
}: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================
  /**
   * Ant Design notification.
   */
  const [notification, contextHolder] = antdNotification.useNotification({
    top: 64,
    stack: {
      threshold: 5,
    },
  });

  // ===========================================================================
  // ===========================================================================
  // Function
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <NotificationContext.Provider
      value={{
        notification,
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
