"use client";

/**
 * Third-party libraries.
 */
import { Call, Device } from "@twilio/voice-sdk";
import { notification } from "antd";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { useApplicationContext } from "@/components/client/context";
import { UserAvailabilityStatus } from "@/components/client/graphql";
import { TwilioCallEvent, useTwilioDevice } from "@/components/client/twilio";
import { CallCustomParameters } from "@/components/common/twilio/types";

export type CallWithCustomProperties = Call & {
  /**
   * Date the call was initiated.
   */
  date?: Date;
  /**
   * Indicates whether the call is muted.
   */
  muted?: boolean;
};

export type SelectedCommunicationLog = {
  // /**
  //  * ID of the communication log.
  //  */
  // id: CommunicationLogCardProps["id"];
  /**
   * Twilio call instance.
   */
  call?: CallWithCustomProperties;
  /**
   * Indicates whether the call is muted.
   */
  muted: boolean;
};

/**
 * Twilio context.
 */
type TwilioContext = {
  /**
   * Active twilio call.
   */
  calls: Call[];
  /**
   * Twilio device.
   */
  device: Device | null;
  /**
   * Twilio device error.
   */
  deviceError: unknown;
  /**
   * Twilio device is loading.
   */
  deviceInitializing: boolean;
  /**
   * Twilio device is registered.
   */
  deviceRegistered: boolean;
  /**
   * Twilio device is registering.
   */
  deviceRegistering: boolean;
  /**
   * Get the call with the specified call SID.
   */
  getCall: (args: { callSid: string }) => CallWithCustomProperties | null;
  /**
   * Register the Device instance with Twilio, allowing it to receive incoming calls.
   *
   * This will open a signaling WebSocket, so the browser tab may show the 'recording' icon.
   *
   * It's not necessary to call device.register() in order to make outgoing calls.
   */
  registerDevice: () => Promise<void>;
  // /**
  //  * Selected communication log.
  //  */
  // selectedCommunicationLog: SelectedCommunicationLog | null;
  // /**
  //  * Set the selected communication log.
  //  */
  // setSelectedCommunicationLog: Dispatch<
  //   SetStateAction<SelectedCommunicationLog | null>
  // >;
  // /**
  //  * Mute the selected call.
  //  */
  // toggleMute: () => void;
  /**
   * Unregister the Device instance with Twilio. This will prevent the Device
   * instance from receiving incoming calls.
   */
  unregisterDevice: () => Promise<void>;
};

/**
 * Twilio related context.
 */
const TwilioContext = React.createContext<TwilioContext>({
  calls: [],
  device: null,
  deviceError: null,
  deviceInitializing: true,
  deviceRegistered: false,
  deviceRegistering: false,
  getCall: () => null,
  registerDevice: () => Promise.resolve(),
  // selectedCommunicationLog: null,
  // setSelectedCommunicationLog: () => {},
  // toggleMute: () => {},
  unregisterDevice: () => {
    return Promise.resolve();
  },
});

/**
 * Use Twilio context.
 */
export const useTwilioContext = () => {
  return React.useContext(TwilioContext);
};

/**
 * Twilio context provider.
 */
export const TwilioContextProvider = ({ children }: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================
  /**
   * The active Twilio call.
   * The purpose of this variable is to store one or more active calls of an
   * agent. This is so we can easily access them if we need to interact with them.
   *
   * @see https://www.twilio.com/docs/voice/sdks/javascript/twiliocall
   */
  const [calls, setCalls] = useState<CallWithCustomProperties[]>([]);

  // /**
  //  * @deprecated
  //  * Use the selectedCommunicationLog from communication-log-context.tsx
  //  *
  //  * Indicates which communication log is currently active on the screen.
  //  * This could control which subpanels are shown.
  //  */
  // const [selectedCommunicationLog, setSelectedCommunicationLog] =
  //   useState<SelectedCommunicationLog | null>(null);

  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * User availability status.
   */
  const { setUserAvailabilityStatus } = useApplicationContext();

  const { user } = useAuthenticationContext();

  const {
    destroyDevice,
    device: device,
    error: deviceError,
    initialize: initializeDevice,
    initializing: deviceInitializing,
    registered: deviceRegistered,
    registering: deviceRegistering,
    registerDevice,
    unregisterDevice,
  } = useTwilioDevice({
    callback: {
      destroyed: () => {
        console.log("Twilio device destroyed.");

        setUserAvailabilityStatus({
          status: UserAvailabilityStatus.Offline,
        });
      },
      error: (error) => {
        console.error("Twilio device error:", error);
      },
      incoming: ({ call }) => {
        console.log("Incoming call:", call);

        /**
         * Add the call to the list of connected calls.
         */
        addCall({ call });
      },
      registered: () => {
        console.log("Twilio device registered.");
      },
      registering: () => {
        console.log("Twilio device registering.");
      },
      token_will_expire: () => {
        console.log("Twilio device token will expire.");
      },
      unregistered: () => {
        console.log("Twilio device unregistered.");
      },
    },
  });

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Remove the call from the list of connected calls.
   */
  function removeCall({
    call,
  }: {
    /**
     * Twilio call instance.
     */
    call: Call;
  }) {
    setCalls(
      (previousCalls) =>
        previousCalls.filter(
          (previousCall) =>
            previousCall.parameters.CallSid !== call.parameters.CallSid
        ) ?? []
    );
  }

  /**
   * Attach event listeners to the call.
   */
  const setCallEventListeners = useCallback(
    ({
      call,
    }: {
      /**
       * Twilio call instance.
       */
      call: Call;
    }) => {
      const onCallAccept = (call: Call) => {
        console.log("Call accepted. Call SID: ", call.parameters.CallSid);
      };

      const onCallCancel = () => {
        console.log("Call cancelled.");
        removeCall({ call });
        // setSelectedCommunicationLog(null);
      };

      const onCallDisconnect = () => {
        console.log("Call disconnected.");
        removeCall({ call });
        // setSelectedCommunicationLog(null);
      };

      const onCallMute = (
        /**
         * Indicates whether the call is muted.
         */
        muted: boolean,
        /**
         * Twilio call instance.
         */
        call: Call
      ) => {
        console.log("Call muted:", muted);
      };

      const onCallReject = () => {
        console.log("Call rejected.");
        removeCall({ call });
      };

      const onCallRing = (call: Call) => {
        console.log("Call ringing.");
      };

      call.on(TwilioCallEvent.ACCEPT, onCallAccept);
      call.on(TwilioCallEvent.CANCEL, onCallCancel);
      call.on(TwilioCallEvent.DISCONNECT, onCallDisconnect);
      call.on(TwilioCallEvent.MUTE, onCallMute);
      call.on(TwilioCallEvent.REJECT, onCallReject);
      call.on(TwilioCallEvent.RINGING, onCallRing);
    },
    []
  );

  /**
   * Add the call to the list of connected calls.
   */
  const addCall = useCallback(
    ({
      call,
    }: {
      /**
       * Twilio call instance.
       */
      call: Call;
    }) => {
      const customCall = call as CallWithCustomProperties;

      // Append the date when the call was initiated to the call object.
      customCall.date = new Date();
      // Track if the call is muted.
      customCall.muted = call.isMuted();

      setCallEventListeners({ call: customCall });

      // console.log("Accepting call...", {
      //   parameters: call.parameters,
      //   customParameters: call.customParameters,
      //   outboundConnectionId: call.outboundConnectionId,
      //   callerInfo: call.callerInfo,
      // });

      setCalls((previousCalls) => [...previousCalls, customCall]);

      // TODO: This must be transfered to the communication log card accept button.
      call.accept();
    },
    [setCallEventListeners]
  );

  /**
   * Get the Twilio call associated with the provided call SID.
   *
   * @returns The Twilio call instance or null.
   */
  const getCall = useCallback(
    ({
      callSid,
    }: {
      /**
       * Twilio call SID of the initiating call.
       * This means that this is the very first Twilio call that initiated the call.
       *
       * Initiating call has a different SID than the connecting call to the agent.
       */
      callSid: string;
    }) => {
      if (!calls?.length) {
        return null;
      }

      /**
       * Twilio call associated with this communication log card.
       */
      return (
        calls.find((twilioCall) => {
          /**
           * Initiating Twilio call SID.
           * This is defined in the connect to agent service on the server.
           */
          const twilioCallSid =
            // twilioCall.customParameters as unknown as CallCustomParameters;
            twilioCall.customParameters.get(
              "callSid"
            ) as CallCustomParameters["callSid"];

          return twilioCallSid === callSid;
        }) ?? null
      );
    },
    [calls]
  );

  // /**
  //  * Mutes a selected call.
  //  */
  // const toggleMute = useCallback(() => {
  //   if (!selectedCommunicationLog?.call) {
  //     return;
  //   }

  //   // Mute the call.
  //   selectedCommunicationLog.call.mute(!selectedCommunicationLog.muted);

  //   // Store the call state.
  //   setSelectedCommunicationLog({
  //     call: selectedCommunicationLog.call,
  //     muted: !selectedCommunicationLog.muted,
  //   });
  // }, [selectedCommunicationLog]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Initialize Twilio device if it hasn't been intiialized yet.
   */
  useEffect(() => {
    /**
     * Only authenticated users are allowed to use Twilio device.
     *
     * Make sure that only one device is initialized.
     */
    if (!user || device || deviceInitializing) {
      return;
    }

    initializeDevice();

    return () => {
      destroyDevice();
    };
  }, [destroyDevice, device, deviceInitializing, initializeDevice, user]);

  /**
   * Register or unregister Twilio device based on user availability status.
   */
  useEffect(() => {
    // Prevent any succeeding code from executing if device is not yet initialized.
    if (!device || deviceInitializing || deviceRegistering) {
      return;
    }

    // Register device if user is available and device is not registered.
    if (!deviceRegistered) {
      registerDevice();
    }
  }, [
    device,
    deviceInitializing,
    deviceRegistered,
    registerDevice,
    deviceRegistering,
  ]);

  /**
   * Display a notification when there's a Twilio device error.
   */
  useEffect(() => {
    // Set the user to offline if there's any Twilio device error.
    if (deviceError) {
      notification.error({
        message: "Twilio device error.",
        description: "An error occurred while updating Twilio device.",
        showProgress: true,
        pauseOnHover: true,
        key: "twilio-device-error",
      });
    }
  }, [deviceError]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <TwilioContext.Provider
      value={{
        calls,
        device,
        deviceError,
        deviceInitializing,
        deviceRegistered,
        getCall,
        registerDevice,
        deviceRegistering,
        // selectedCommunicationLog,
        // setSelectedCommunicationLog,
        // toggleMute,
        unregisterDevice,
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
};
