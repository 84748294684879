/**
 * Keys used for defining a system preference.
 * Values accepted by the keys are defined on the documentation of each key.
 */
export enum SystemPreferenceKey {
  /**
   * Indicates whether the business is open or closed.
   *
   * Valid values are:
   *  - open: The system will accept client calls.
   *  - closed: The system will reject client calls.
   */
  BUSINESS_STATUS = "business-status",
  /**
   * Indicates whether the system should use the Session Initiation Protocol (SIP).
   * Turning this on would use the SIP Phone Number as the default phone number for
   * outbound calls.
   *
   * Valid values are:
   * - enabled
   * - disabled
   */
  SESSION_INITIATION_PROTOCOL = "session-initiation-protocol",
}
